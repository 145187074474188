import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import MainPage from './mainPage'
import ApplyPage from './applyPage'
import ApplyReg from './applyReg'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCngy5HL44YqyNVqgHdCyXYT6SoJhBGfmg",
  authDomain: "kalgc-6f8cb.firebaseapp.com",
  projectId: "kalgc-6f8cb",
  storageBucket: "kalgc-6f8cb.appspot.com",
  messagingSenderId: "734411263947",
  appId: "1:734411263947:web:5cfd4437f3f731ac19f04e",
  measurementId: "G-G1H5EX7SH1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'))

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <MainPage />
    )
  },
  {
    path: 'apply',
    element: (
      <ApplyPage />
    )
  },
  {
    path: 'apply/divan',
    element: (
      <ApplyReg type="divan" />
    )
  },

  {
    path: 'apply/admin',
    element: (
      <ApplyReg type="admin" />
    )
  },

  {
    path: 'apply/chair',
    element: (
      <ApplyReg type="chair" />
    )
  },

  {
    path: 'apply/press',
    element: (
      <ApplyReg type="press" />
    )
  }
])

root.render(
  <RouterProvider router={router} />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import './ApplicationCard.css'
import React from 'react'
import PropTypes from 'prop-types'

const ApplicationCard = ({ href, name, image }) => {
  return (
    <a href={href} style={{ background: `url(${image}) no-repeat`, backgroundSize: 'cover', filter: "grayscale(0%) sepia(20%)", backgroundPosition: "center", }} className="parent">
      <h1 className="name">{name}</h1>
    </a>
  )
}

ApplicationCard.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string
}

export default ApplicationCard

import './Apply.css'
import Navbar from './Navbar'
import ApplicationCard from './applicationCard'
import React from 'react'

const ApplyPage = () => {
  return (
    <div className="App">
      <Navbar />
      <h1>Başvuru seçenekleri</h1>
      <div className='cards'>
        <ApplicationCard href="/apply/divan" name="Divan" image="https://live.staticflickr.com/65535/53359830541_9c586d1240_b.jpg" />
        {/*}
        <ApplicationCard href="https://forms.gle/YJ7zWN3aC85LLJUu5" name="Delege" image="https://qph.cf2.quoracdn.net/main-qimg-bded2fdf7a076eebf9b99ea822baa5ec.webp" />
        <ApplicationCard href="https://forms.gle/f3Q8CHwL9iBnzBx16" name="Divan" image="https://live.staticflickr.com/65535/53359830541_9c586d1240_b.jpg" />
        <ApplicationCard href="https://forms.gle/zLfKmb1vDwC4MTFS6" name="Gözlemci" image="https://www.un.org/sites/un2.un.org/files/2021/11/cr5a0180.jpg" />
        <ApplicationCard href="https://forms.gle/1Ky5FN7UDkpoPkw68" name="Basın" image="https://trthaberstatic.cdn.wp.trt.com.tr/resimler/1676000/1676064.jpg" />
        {*/}
      </div>
      <footer style={{ bottom: 0, left: 0, marginTop: '2.5rem', width: '100%', height: '2.5rem', position: 'fixed', backgroundColor: '#4b145e', padding: 5 }}>
        <p>Made with ❤️ by <a href="https://ahmetefeakyazi.codes" className="github">Ahmet Efe Akyazı</a> & <a href="https://mondr.au" className="github">Ali Furkan Kargı</a></p>
      </footer>
    </div>
  )
}

export default ApplyPage

import "./applyReg.css"
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Navbar from './Navbar'

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const RenderForm = ({ type }) => {
  switch (type) {
    case 'divan':
      return <iframe className="tally" data-tally-src="https://tally.so/r/wQzA4g?transparentBackground=1" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="KALGÇ'25 Divan Başvuru "></iframe>

    default:
      return <h1>error</h1>
  }
}

const ApplyReg = ({ type }) => {
  const [applyType, setApplyType] = useState(null)
  useEffect(() => {
    setApplyType(type)
    if (type == "divan") {
      const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

      const load = () => {
        // Load Tally embeds
        if (typeof window.Tally !== 'undefined') {
          window.Tally.loadEmbeds();
          return;
        }

        // Fallback if window.Tally is not available
        document
          .querySelectorAll('iframe[data-tally-src]:not([src])')
          .forEach((iframeEl) => {
            iframeEl.src = iframeEl.dataset.tallySrc;
          });
      };

      // If Tally is already loaded, load the embeds
      if (typeof Tally !== 'undefined') {
        load();
        return;
      }

      // If the Tally widget script is not loaded yet, load it
      if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
        const script = document.createElement('script');
        script.src = widgetScriptSrc;
        script.onload = load;
        script.onerror = load;
        document.body.appendChild(script);
        return;
      }
    }
  }, [type])
  return (
    <div className="App">
      <Navbar />
      <div className="form">
        <RenderForm type={applyType} />
      </div>
    </div>
  )
}

ApplyReg.propTypes = {
  type: PropTypes.string
}

export default ApplyReg
